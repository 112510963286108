export const GetHLTV_1 = (kills = 0, rounds, deaths = 0, k2 = 0, k3 = 0, k4 = 0, k5 = 0) => {
  const k1 = kills - k2 - k3 - k4 - k5
  const Weight_KPR = 0.679  // weight kills per round
  const Weight_SPR = 0.317  // weight survived rounds per round
  const Weight_RMK = 1.277  // weight value calculated from rounds with multiple kills (1k + 4*2k + 9*3k + 16*4k + 25*5k)

  const KillRating = kills / rounds / Weight_KPR
  const SurvivalRating = (rounds - deaths) / rounds / Weight_SPR
  const RoundsWithMultipleKillsRating = (k1 + 4 * k2 + 9 * k3 + 16 * k4 + 25 * k5) / rounds / Weight_RMK

  return ((KillRating + 0.7 * SurvivalRating + RoundsWithMultipleKillsRating) / 2.7).toFixed(2)
}
