<template>
  <div v-if="infos.data" id="modal">
    <div v-for="(info, id) in infos.data" :key="id" class="custom-modal">
      <div :class="info.type === 'error'
              ? 'bg-danger text-white'
              : info.type === 'warning'
              ? 'bg-warning text-secondary'
              : info.type === 'success'
              ? 'bg-success text-white'
              : 'bg-secondary text-white'"
           class="card">
        <div class="card-body d-flex justify-content-between">
          <span class="info-text">{{ info.message }}</span>
          <button aria-label="Close" class="btn-close" type="button" @click="closeModal(id)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {onMounted, reactive} from "vue";

export default {
  name: "InfoModal",
  setup() {
    const store = useStore()
    const infos = reactive({
      data: []
    })

    const closeModal = (id) => {
      store.commit('removeInfoState', id)
    }

    onMounted(() => {
      store.subscribe(((mutation, state) => {
        if (mutation.type === 'changeInfoState') {
          infos.data = state.info

          setTimeout(() => {
            closeModal(store.state.info.length - 1)
          }, 5000)
        }
      }))
    })

    return {infos, closeModal}
  }
}
</script>

<style lang="scss" scoped>
#modal {
  --height: 56px;

  .card {
    z-index: 10;
    position: absolute;
    right: 1rem;
    opacity: .8;
    width: min(100vw - 2rem, 50ch);
    height: var(--height);

    .btn-close {
      background-color: white;
      opacity: .5;
    }

    .info-text {
      font-size: .8rem;
    }
  }

  @for $i from 1 through 10 {
    .custom-modal:nth-of-type(#{$i}) {
      .card {
        @if $i == 1 {
          margin: 1rem 0;
        } @else {
          margin-top: calc(#{$i}rem + (#{$i} - 1) * var(--height));
        }
      }
    }
  }
}
</style>
