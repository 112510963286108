import router from "../router";

export const GoToMatch = (id) => {
    router.push({name: 'Match', params: {match_id: id}})
}

export const GoToPlayer = (id) => {
    router.push({name: 'Player', params: {id: id}})
}

export const GoToError = (code) => {
    router.push({name: code})
}

export const GoToLink = (link) => {
    router.replace(link)
}
