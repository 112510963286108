import { createStore } from 'vuex'

export default createStore({
  state: {
    id64: '',
    vanityUrl: '',
    matchDetails: {},
    playerDetails: {},
    playersArr: [],
    scroll_state: 0,
    info: []
  },
  mutations: {
    changeId64(state, payload) {
      state.id64 = payload.id
    },
    changeVanityUrl(state, payload) {
      state.vanityUrl = payload.id
    },
    changeMatchDetails(state, payload) {
      state.matchDetails = payload.data
    },
    changePlayerDetails(state, payload) {
      state.playerDetails = payload.data
    },
    changePlayersArr(state, payload) {
      state.playersArr = payload.data
    },
    changeScrollState(state, payload) {
      state.scroll_state = payload
    },
    changeInfoState(state, payload) {
      state.info.push(payload.data)
    },
    resetId64(state) {
      state.id64 = ''
    },
    resetVanityUrl(state) {
      state.vanityUrl = ''
    },
    resetMatchDetails(state) {
      state.matchDetails = {}
    },
    resetPlayerDetails(state) {
      state.playerDetails = {}
    },
    resetPlayersArr(state) {
      state.playersArr = []
    },
    resetScrollState(state) {
      state.scroll_state = 0
    },
    resetInfoState(state) {
      state.info = []
    },
    removeInfoState(state, id) {
      state.info.splice(id, 1)
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
  }
})
