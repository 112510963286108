import {createRouter, createWebHistory} from 'vue-router'

function lazyLoadView(view) {
  return () => import(`@/views/${view}.vue`)
}

function lazyLoadComponent(view) {
  return () => import(`@/components/${view}.vue`)
}

function lazyLoadErrorPages(view) {
  return () => import(`@/views/errorPages/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
      main: lazyLoadView('HomeView')
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    components: {
      main: lazyLoadView('PrivacyPolicy')
    }
  },
  {
    path: '/matches',
    name: 'Explore',
    components: {
      main: lazyLoadView('ExploreView')
    }
  },
  {
    path: '/player/:id',
    name: 'Player',
    components: {
      main: lazyLoadView('PlayerView'),
    },
    props: true
  },
  {
    path: '/match/:match_id',
    name: 'Match',
    components: {
      main: lazyLoadView('MatchView')
    },
    props: true,
    children: [
      {
        path: '',
        components: {
          score: lazyLoadComponent('ScoreTeam')
        }
      },
      {
        path: 'economy',
        components: {
          score: lazyLoadComponent('EqValueGraph')
        }
      },
      {
        path: 'details',
        components: {
          score: lazyLoadComponent('CompDetails')
        }
      },
      {
        path: 'flashes',
        components: {
          score: lazyLoadComponent('FlashChart')
        }
      },
      {
        path: 'damage',
        components: {
          score: lazyLoadComponent('DamageSite')
        }
      },
      {
        path: 'chat',
        components: {
          score: lazyLoadComponent('MatchChatHistory')
        }
      }
    ]
  },
  {
    path: '/404',
    name: '404',
    components: {
      main: lazyLoadErrorPages('Error404')
    }
  },
  {
    path: '/500',
    name: '500',
    components: {
      main: lazyLoadErrorPages('Error500')
    }
  },
  {
    path: '/502',
    name: '502',
    components: {
      main: lazyLoadErrorPages('Error502')
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})

export default router
