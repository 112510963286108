<template>
  <nav class="navbar navbar-expand-md navbar-dark fixed-top">
    <div class="container">
      <router-link class="navbar-brand" to="/" @click="closeNav('mainNav')">
        <img alt="logo-nav"
             class="logo-nav"
             src="/images/logo.svg">
      </router-link>
      <button aria-controls="mainNav" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler"
              data-bs-target="#mainNav" data-bs-toggle="collapse" type="button">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div id="mainNav" class="collapse navbar-collapse navbar-nav justify-content-between">
        <ul class="list-unstyled">
          <li class="nav-item">
            <router-link class="nav-link" to="/matches" @click="closeNav('mainNav')">
              Matches
            </router-link>
          </li>
        </ul>
        <form id="searchform" class="d-flex" @keydown.enter.prevent="parseSearch" @submit.prevent="parseSearch">
          <label for="search">
            <i class="fa fa-search"></i>
          </label>

          <input id="search" v-model="data.searchInput" aria-label="Search"
                 autocomplete="off"
                 class="form-control bg-transparent border-0"
                 placeholder="SteamID64, Profile Link or Custom URL"
                 title="SteamID64, Profile Link or Custom URL"
                 type="search">
          <button
              id="search-button"
              class="btn border-2 btn-outline-info"
              type="button"
              @click="parseSearch"
          >
            Search!
          </button>
        </form>
      </div>

    </div>
  </nav>
</template>

<script>
import {reactive} from "vue";
import {useStore} from 'vuex'
import {closeNav, GetUser, GoToPlayer} from '@/utils'
import {StatusCodes as STATUS} from "http-status-codes";

export default {
  name: 'CompNav',
  setup() {
    const store = useStore()
    const data = reactive({
      searchInput: '',
    })

    const parseSearch = async () => {
      const input = data.searchInput
      const customUrlPattern = 'https://steamcommunity.com/id/'
      const profileUrlPattern = 'https://steamcommunity.com/profiles/'
      const id64Pattern = /^\d{17}$/
      const vanityPattern = /^[A-Za-z0-9-_]{3,32}$/

      store.commit({
        type: 'changeVanityUrl',
        id: ''
      })
      store.commit({
        type: 'changeId64',
        id: ''
      })

      if (data.searchInput !== '') {
        if (id64Pattern.test(input)) {
          store.commit({
            type: 'changeId64',
            id: input
          })
        } else if (input.match(customUrlPattern)) {
          store.commit({
            type: 'changeVanityUrl',
            id: input.split('/')[4].split('?')[0]
          })
        } else if (input.match(profileUrlPattern)) {
          const tmp = input.split('/')[4].split('?')[0]
          if (id64Pattern.test(tmp)) {
            store.commit({
              type: 'changeId64',
              id: tmp
            })
          }
        } else {
          store.commit({
            type: 'changeVanityUrl',
            id: input
          })
        }

        if (store.state.vanityUrl && !vanityPattern.test(store.state.vanityUrl)) {
          store.commit({
            type: 'changeInfoState',
            data: {
              statuscode: STATUS.NOT_ACCEPTABLE,
              message: 'Only alphanumeric symbols, "_", and "-", between 3-32 characters',
              type: 'warning'
            }
          })
          store.commit({
            type: 'changeVanityUrl',
            id: ''
          })
          data.searchInput = ''
        }

        if (store.state.id64 !== '' || store.state.vanityUrl !== '') {
          const resData = await GetUser(store, store.state.vanityUrl || store.state.id64)

          if (resData !== null) {
            data.searchInput = ''
            document.activeElement.blur()

            store.commit({
              type: 'changePlayerDetails',
              data: resData
            })

            if (store.state.vanityUrl) {
              closeNav('mainNav')
              GoToPlayer(store.state.vanityUrl)
            } else if (store.state.id64) {
              closeNav('mainNav')
              GoToPlayer(store.state.id64)
            }
          }
        }
      }
    }

    document.addEventListener('click', (e) => {
      if (!e.target.attributes.id)
        closeNav('mainNav')
    })

    return {
      data, parseSearch, closeNav
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: var(--bs-warning);
}

nav {
  max-width: 100vw;
  width: 100vw;
  height: 70px;
  background: rgba(16, 18, 26, .9);
  box-shadow: 0 1px 10px 0 #111;
  z-index: 2;
  vertical-align: center !important;

  .navbar-brand {
    img {
      width: 75px;
      height: auto;
    }

    &:focus-visible {
      outline: none;
      box-shadow: 0 4px 2px -2px var(--bs-warning);
    }

    &:hover {
      color: var(--bs-warning);
    }
  }

  ul li {
    font-size: 1.5rem;
    font-weight: lighter;
    margin: 22px 0 0 10px;
    cursor: pointer;
    transition: 100ms ease-in-out;

    .nav-link {
      text-decoration: none;
      color: white !important;

      .router-link-exact-active {
        box-shadow: 0 4px 2px -2px var(--bs-warning);
      }

      &:focus-visible {
        outline: none;
        box-shadow: 0 4px 2px -2px var(--bs-warning);
      }

      &:hover {
        color: #bdbdbd !important;
        transition: 250ms ease-in-out;
        cursor: pointer;
        box-shadow: 0 4px 2px -2px var(--bs-warning);
      }
    }
  }

  form {
    position: relative;

    svg {
      width: 24px;
      height: 24px;
      fill: currentColor;
    }

    label {
      padding-top: 6px;
      font-size: 1.4rem;
    }

    input[type="search"] {
      min-width: 300px;
      max-width: 300px;

      &:focus {
        box-shadow: 0 4px 2px -2px rgba(95, 120, 146, 0.59);
        transition: .2s ease-in-out;
        transform: scale(.975);
      }

      &::placeholder {
        color: #aaa;
        font-size: .9rem;
      }
    }

    .alert {
      position: absolute;
      right: 0;
      top: 55px;
    }
  }
}

@media screen and (max-width: 410px) {
  form {
    margin-left: auto !important;
    margin-right: auto !important;

    input[type="search"] {
      margin-left: 0 !important;
      max-width: 60vw !important;
      min-width: 60vw !important;
    }
  }
}

@media screen and (max-width: 455px) and (min-width: 410px) {
  form {
    margin-left: auto !important;
    margin-right: auto !important;

    input[type="search"] {
      margin-left: 0 !important;
      max-width: 65vw !important;
      min-width: 65vw !important;
    }
  }
}

@media screen and (max-width: 610px) and (min-width: 456px) {
  form {
    margin-left: auto !important;
    margin-right: auto !important;

    input[type="search"] {
      margin-left: 0 !important;
      max-width: 68vw !important;
      min-width: 68vw !important;
    }
  }
}

@media screen and (max-width: 768px) {
  nav {
    button {
      outline: 1px solid var(--bs-primary);
      margin-left: auto;
      float: right;

      &:focus {
        box-shadow: none;
        outline: 1px solid var(--bs-primary);
      }
    }

    .navbar-collapse {
      background: var(--bs-secondary);
      border-radius: 5px;
      border: 1px solid var(--bs-primary)
    }

    #mainNav {
      ul {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 100%;

        li {
          line-height: 1;
          padding: 0 0 20px 0;
          border-bottom: 1px solid rgba(255, 255, 255, .1);
        }
      }

      form {
        max-width: 87vw;
        margin-left: -40px;

        label {
          display: none;
        }

        input[type="search"] {
          margin-bottom: 15px;
          margin-left: 37px;
          max-width: 400px;
          min-width: 400px;
          font-size: 1rem;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &::placeholder {
            background: var(--bs-body-bg);
          }
        }

        button {
          margin-left: 10px;
          display: block;
          margin-top: -2px;
          height: 40px;
        }
      }
    }
  }
}
</style>
