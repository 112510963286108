<template>
  <div class="footer bg-secondary text-center pt-4 pb-2">
    <div class="text">
      <p class="fs-6">Made with <i class="fa fa-heart text-warning" aria-hidden="true"></i>, <span
          style="color: #41b883">Vue.js</span> and<a aria-label="Gitea" class="text-warning ms-2"
                                                     href="https://somegit.dev/CSGOWTF"
                                                     target="_blank">
        <i aria-hidden="true" class="fa fa-gitea"></i>
      </a></p>

      <div class="d-flex justify-content-center align-items-center gap-4">
        <p><a class="text-decoration-none text-warning"
              href="https://somegit.dev/CSGOWTF/csgowtf/issues"
              target="_blank">Issue Tracker</a></p>
        <p class="text-muted">Version {{ version }}</p>
        <p>
          <a class="text-decoration-none text-warning" href="/privacy-policy">Privacy Policy</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompFooter",
  setup() {
    const version = process.env.VUE_APP_VERSION
    return {version}
  }
}
</script>

<style lang="scss" scoped>
.footer {
  .fa-gitea:hover {
    color: #609926 !important;
  }

  .fa-heart:hover {
    color: red !important;
  }

  p {
    font-size: .85rem;
  }
}
</style>
