import {GoToError} from "@/utils/GoTo";

export const errorHandling = (code) => {
  if (code === 404) {
    GoToError('404')
  } else if (code === 500) {
    GoToError('500')
  } else if (code === 502) {
    GoToError('502')
  } else {
    GoToError('404')
  }
}

export const setTitle = (title) => {
  document.title = `${title} | csgoWTF`
}

export const closeNav = (navSelector) => {
  const nav = document.getElementById(navSelector)
  if (nav)
    if (nav.classList.contains('show'))
      nav.classList.remove('show')
}

export const GetWinLoss = (matchResult, teamId) => {
  if (matchResult === teamId) {
    return 'win'
  } else if (matchResult === 0) {
    return 'draw'
  } else {
    return 'loss'
  }
}

export const truncate = (str, len, ending) => {
  if (len == null)
    len = 100

  if (ending == null)
    ending = '..'

  if (str.length > len)
    return str.substring(0, len - ending.length) + ending
  else
    return str
}

export const checkStatEmpty = (stat) => {
  if (stat)
    return stat
  return 0
}

export const FixMapName = (map) => {
  return map.split('_')[1].replace(/^\w/, c => c.toUpperCase());
}

export const getPlayerArr = (stats, team, color) => {
  let arr = []
  for (let i = (team - 1) * 5; i < team * 5; i++) {
    arr.push({
      value: truncate(stats[i].player.name, 12),
      textStyle: {
        color: color ? getComputedStyle(document.documentElement).getPropertyValue(`--csgo-${stats[i].color}`) : 'white'
      }
    })
  }
  arr.reverse()
  return arr
}

export const constructAvatarUrl = (hash, size) => {
  const base = 'https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars'
  const imgSize = size ? `_${size}` : ''

  if (hash) {
    const hashDir = hash.substring(0, 2)

    return `${base}/${hashDir}/${hash}${imgSize}.jpg`
  }
}

export const sortObjectValue = (obj, direction = 'asc') => {
  const sortable = []
  for (let key in obj) {
    sortable.push([key, obj[key]])
  }

  if (direction === 'asc') {
    sortable.sort((a, b) => {
      return a[1] - b[1]
    })
  }
  if (direction === 'desc') {
    sortable.sort((a, b) => {
      return b[1] - a[1]
    })
  }

  return sortable
}

export const CreatePlayersArray = (stats) => {
  let arr = []
  for (let i in stats) {
    arr.push({team_id: stats[i].team_id, player: stats[i].player})
  }
  return arr
}

export const scrollToPos = (pos = 0) => {
  window.scrollTo({
    top: pos,
    left: 0,
    behavior: 'smooth'
  })
}

export const StripControlCodes = (str = '') => {
  const regexpControl = /\p{C}/gu;
  return str.replace(regexpControl, '')
}

export const ProcessName = (str = '') => {
  return StripControlCodes(str).trim()
}
