import axios from "axios";
import {StatusCodes as STATUS} from "http-status-codes";
import {AUTHCODE_REGEX, SHARECODE_REGEX} from "@/constants";

const API_URL = process.env.VUE_APP_API_URL

// /player/<id>   GET   returns player <id> details (last 10 matches)
export const GetUser = async (store, id) => {
  let response = null

  await axios
    .get(`${API_URL}/player/${id}`)
    .then((res) => {
      if (res.status === STATUS.OK)
        response = res.data
    })
    .catch((err) => {
      let message = ''

      switch (err.response.status) {
        case STATUS.BAD_REQUEST:
          message = 'Bad request'
          break
        case STATUS.NOT_FOUND:
          message = 'Player not found'
          break
        case STATUS.INTERNAL_SERVER_ERROR:
          message = 'Unable to get meta-stats or player'
          break
        default:
          message = 'An unknown error occurred'
      }
      store.commit({
        type: 'changeInfoState',
        data: {
          statuscode: err.response.status,
          message,
          type: 'error'
        }
      })
    })

  return response
}

// /player/<id>/meta/<limit>   GET   returns player <id> meta-stats with <limit>
export const GetPlayerMeta = async (store, player_id, limit = 4) => {
  let response = null

  await axios
    .get(`${API_URL}/player/${player_id}/meta/${limit}`)
    .then((res) => {
      if (res.status === STATUS.OK)
        response = res.data
    })
    .catch((err) => {
      let message = ''

      switch (err.response.status) {
        case STATUS.BAD_REQUEST:
          message = 'Bad request'
          break
        case STATUS.NOT_FOUND:
          message = 'Player not found'
          break
        case STATUS.INTERNAL_SERVER_ERROR:
          message = 'Unable to get player meta'
          break
        default:
          message = 'An unknown error occurred'
      }
      store.commit({
        type: 'changeInfoState',
        data: {
          statuscode: err.response.status,
          message,
          type: 'error'
        }
      })
    })

  return response
}

// /player/<id>/next/<unix>   GET   returns 20 matches after <unix> for player <id>
export const LoadMoreMatches = async (store, player_id, date) => {
  let response = null

  await axios
    .get(`${API_URL}/player/${player_id}/next/${date}`)
    .then((res) => {
      if (res.status === STATUS.OK)
        response = res.data
    })
    .catch((err) => {
      let message = ''

      switch (err.response.status) {
        case STATUS.BAD_REQUEST:
          message = 'Bad request'
          break
        case STATUS.NOT_FOUND:
          message = 'Player not found'
          break
        case STATUS.INTERNAL_SERVER_ERROR:
          message = 'Unable to get meta-stats or player'
          break
        default:
          message = 'An unknown error occurred'
      }
      store.commit({
        type: 'changeInfoState',
        data: {
          statuscode: err.response.status,
          message,
          type: 'error'
        }
      })
    })

  return response
}

// /player/<id>/track   POST   Track player <id> FORM_DATA: authcode, [sharecode]
export const TrackMe = async (store, id64, authcode, sharecode = '') => {
  let status = null
  let message = ''

  if (sharecode !== '' && !SHARECODE_REGEX.test(sharecode)) {
    status = STATUS.IM_A_TEAPOT
    message = 'Sharecode is invalid'
  }
  if (authcode === '' || !AUTHCODE_REGEX.test(authcode.toUpperCase())) {
    status = STATUS.IM_A_TEAPOT
    message = 'Authcode is invalid'
  }

  if (status === null && message === '') {
    await axios
      .post(`${API_URL}/player/${id64}/track`, `authcode=${authcode.toUpperCase()}&sharecode=${sharecode}`)
      .then((res) => {
        if (res.status === STATUS.ACCEPTED) {
          status = STATUS.ACCEPTED
          message = 'Tracking successful'
        }
      })
      .catch((err) => {
        switch (err.response.status) {
          case STATUS.BAD_REQUEST:
            message = 'Invalid arguments'
            break
          case STATUS.NOT_FOUND:
            message = 'Player not found'
            break
          case STATUS.SERVICE_UNAVAILABLE:
            message = 'Service currently unavailable - Please try again later'
            break
          case STATUS.UNAUTHORIZED:
            message = 'Authcode is invalid'
            break
          case STATUS.PRECONDITION_FAILED:
            message = 'Sharecode is invalid or missing'
            break
          case STATUS.INTERNAL_SERVER_ERROR:
            message = 'Service is currently unavailable - Please try again later'
            break
          default:
            message = 'An unknown error occurred'
        }
        status = err.response.status
      })
  }

  store.commit({
    type: 'changeInfoState',
    data: {
      statuscode: status,
      message,
      type: 'error'
    }
  })
  return status
}

// /match/<id>   GET   returns details for match <id>
export const GetMatchDetails = async (store, match_id) => {
  let response = null

  await axios
    .get(`${API_URL}/match/${match_id}`)
    .then((res) => {
      if (res.status === STATUS.OK)
        response = res.data
    })
    .catch((err) => {
      let message = ''

      switch (err.response.status) {
        case STATUS.BAD_REQUEST:
          message = 'Error parsing matchID'
          break
        case STATUS.NOT_FOUND:
          message = 'Match not found'
          break
        case STATUS.INTERNAL_SERVER_ERROR:
          message = 'Unable to get match data'
          break
        default:
          message = 'An unknown error occurred'
      }
      store.commit({
        type: 'changeInfoState',
        data: {
          statuscode: err.response.status,
          message,
          type: 'error'
        }
      })
    })

  return response
}

// /match/<id>/rounds   GET   returns round-stats for match <id>
export const GetPlayerValue = async (store, match_id) => {
  let response = null

  await axios
    .get(`${API_URL}/match/${match_id}/rounds`)
    .then((res) => {
      if (res.status === STATUS.OK)
        response = res.data
    })
    .catch((err) => {
      let message = ''

      switch (err.response.status) {
        case STATUS.BAD_REQUEST:
          message = 'Error parsing matchID'
          break
        case STATUS.NOT_FOUND:
          message = 'Match not found'
          break
        case STATUS.INTERNAL_SERVER_ERROR:
          message = 'Unable to get match data'
          break
        default:
          message = 'An unknown error occurred'
      }
      store.commit({
        type: 'changeInfoState',
        data: {
          statuscode: err.response.status,
          message,
          type: 'error'
        }
      })
    })

  return response
}

// /match/<id>/weapons   GET   returns weapon-stats for match <id>
export const GetWeaponDmg = async (store, match_id) => {
  let response = null

  await axios
    .get(`${API_URL}/match/${match_id}/weapons`)
    .then((res) => {
      if (res.status === STATUS.OK)
        response = res.data
    })
    .catch((err) => {
      let message = ''

      switch (err.response.status) {
        case STATUS.BAD_REQUEST:
          message = 'Bad request'
          break
        case STATUS.NOT_FOUND:
          message = 'Weapon damage not found'
          break
        case STATUS.INTERNAL_SERVER_ERROR:
          message = 'Unable to get weapon damage'
          break
        default:
          message = 'An unknown error occurred'
      }
      store.commit({
        type: 'changeInfoState',
        data: {
          statuscode: err.response.status,
          message,
          type: 'error'
        }
      })
    })

  return response
}

// /match/<id>/chat   GET   returns chat history for match <id>
export const GetChatHistory = async (store, match_id) => {
  let response = null

  await axios
    .get(`${API_URL}/match/${match_id}/chat`)
    .then((res) => {
      if (res.status === STATUS.OK)
        response = res.data
    })
    .catch((err) => {
      let message = ''

      switch (err.response.status) {
        case STATUS.BAD_REQUEST:
          message = 'Bad request'
          break
        case STATUS.NOT_FOUND:
          message = 'Weapon damage not found'
          break
        case STATUS.INTERNAL_SERVER_ERROR:
          message = 'Unable to get weapon damage'
          break
        default:
          message = 'An unknown error occurred'
      }
      store.commit({
        type: 'changeInfoState',
        data: {
          statuscode: err.response.status,
          message,
          type: 'error'
        }
      })
    })

  return response
}

// /matches/<id>/chat/<langCode>   GET   returns chat history for match <id> with translated sections
export const GetChatHistoryTranslated = async (store, match_id) => {
  let response = null

  await axios
    .get(`${API_URL}/match/${match_id}/chat?translate=1`)
    .then((res) => {
      if (res.status === STATUS.OK)
        response = res.data
    })
    .catch((err) => {
      let message = ''

      switch (err.response.status) {
        case STATUS.BAD_REQUEST:
          message = 'Bad request'
          break
        case STATUS.NOT_FOUND:
          message = 'Chat was not found'
          break
        case STATUS.INTERNAL_SERVER_ERROR:
          message = 'Unable to get chat'
          break
        default:
          message = 'An unknown error occurred'
      }
      store.commit({
        type: 'changeInfoState',
        data: {
          statuscode: err.response.status,
          message,
          type: 'error'
        }
      })
    })

  return response
}

// /matches   GET   returns last 20 matches in DB
export const GetMatches = async (store) => {
  let response = null

  await axios
    .get(`${API_URL}/matches`)
    .then((res) => {
      if (res.status === STATUS.OK)
        response = res.data
    })
    .catch((err) => {
      let message = ''

      switch (err.response.status) {
        case STATUS.BAD_REQUEST:
          message = 'Bad request'
          break
        case STATUS.INTERNAL_SERVER_ERROR:
          message = 'Unable to marshal JSON'
          break
        default:
          message = 'An unknown error occurred'
      }
      store.commit({
        type: 'changeInfoState',
        data: {
          statuscode: err.response.status,
          message,
          type: 'error'
        }
      })
    })

  return response
}

// /matches/next/<unix>   GET   returns 20 matches after time <unix>
export const LoadMoreMatchesExplore = async (store, date) => {
  let response = null

  await axios
    .get(`${API_URL}/matches/next/${date}`)
    .then((res) => {
      if (res.status === STATUS.OK)
        response = res.data
    })
    .catch((err) => {
      let message = ''

      switch (err.response.status) {
        case STATUS.BAD_REQUEST:
          message = 'Bad request'
          break
        case STATUS.INTERNAL_SERVER_ERROR:
          message = 'Unable to load more matches'
          break
        default:
          message = 'An unknown error occurred'
      }
      store.commit({
        type: 'changeInfoState',
        data: {
          statuscode: err.response.status,
          message,
          type: 'error'
        }
      })
    })

  return response
}
