export const SaveLastVisitedToLocalStorage = (data) => {
    let a = JSON.parse(localStorage.getItem('recent-visited')) || [];

    if (a.length === 0) {
        a.unshift(data);
    } else if (a.length === 9) {
        if (a.find(p => p.steamid64 === data.steamid64)) {
            a.shift()
            a.splice(a.findIndex(i => i.steamid64 === data.steamid64), 1)
            a.unshift(data)
        } else if (!a.find(p => p.steamid64 === data.steamid64)) {
            a.shift()
            a.unshift(data)
        }
    } else if (a.length > 0 && a.length < 9) {
        if (a.find(p => p.steamid64 === data.steamid64)) {
            a.splice(a.findIndex(i => i.steamid64 === data.steamid64), 1)
            a.unshift(data)
        } else if (!a.find(p => p.steamid64 === data.steamid64)) {
            a.unshift(data)
        }
    }

    localStorage.setItem('recent-visited', JSON.stringify(a));
}
