import {DateTime, Duration} from "luxon";

export const ConvertTickToTime = (tick, rate = 64) => {
  const time = Duration.fromObject({hours: 0, minutes: 0, seconds: tick / rate || 0})

  if (time.hours > 1)
    return time.toFormat('hh:mm:ss')
  else if (time.hours < 1)
    return time.toFormat('mm:ss')
}

export const FormatDuration = (d) => {
  const duration = Duration.fromObject({hours: 0, minutes: 0, seconds: d}).normalize().toObject()

  if (duration.hours > 1)
    return `${duration.hours} h ${duration.minutes} min`
  else if (duration.hours < 1)
    return `${duration.minutes} min`
}

export const FormatFullDuration = (d) => {
  const duration = Duration.fromObject({hours: 0, minutes: 0, seconds: d}).normalize()

  if (duration.hours > 1)
    return duration.toFormat('hh:mm:ss')
  else if (duration.hours < 1)
    return duration.toFormat('mm:ss')
}

export const FormatDate = (date) => {
  const matchDate = DateTime.fromSeconds(date || 0)
  const diff = DateTime.now().diff(matchDate)

  if (diff.as('days') > 8)
    return matchDate.toLocaleString({weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric'})
  else
    return matchDate.toRelative()
}

export const FormatFullDate = (date) => {
  const matchDate = DateTime.fromSeconds(date || 0)

  return matchDate.toLocaleString({
    weekday: 'short',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  })
}

export const FormatVacDate = (date, match) => {
  const vacDate = DateTime.fromSeconds(date || 0)
  const matchDate = DateTime.fromSeconds(match || 0)

  if (vacDate.diff(matchDate).as('days') >= -30) {
    return vacDate.toRelative()
  } else {
    return ''
  }
}

export const MatchNotParsedTime = (match) => {
  const matchDate = DateTime.fromSeconds(match || 0)

  return matchDate.diffNow().as('hours') >= -2;
}
