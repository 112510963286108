export const DisplayRank = (rankNr = 0) => {
    const rankMap = new Map([
        [0, 'Unranked'],
        [1, 'Silver I'],
        [2, 'Silver II'],
        [3, 'Silver III'],
        [4, 'Silver IV'],
        [5, 'Silver Elite'],
        [6, 'Silver Elite Master'],
        [7, 'Gold Nova I'],
        [8, 'Gold Nova II'],
        [9, 'Gold Nova III'],
        [10, 'Gold Nova IV'],
        [11, 'Master Guardian I'],
        [12, 'Master Guardian II'],
        [13, 'Master Guardian Elite'],
        [14, 'Distinguished Master Guardian'],
        [15, 'Legendary Eagle'],
        [16, 'Legendary Eagle Master'],
        [17, 'Supreme Master First Class'],
        [18, 'Global Elite'],
    ])
    return [`/images/rank_icons/skillgroup${rankNr}.svg`, rankMap.get(rankNr)]
}

export const DisplayWeapon = (weaponId) => {
  const wepaonMap = new Map([
    [1, 'p2000'],
    [2, 'glock'],
    [3, 'p250'],
    [4, 'deagle'],
    [5, 'fiveseven'],
    [6, 'elite'],
    [7, 'tec9'],
    [8, 'cz75a'],
    [9, 'usp_silencer'],
    [10, 'revolver'],

    [101, 'mp7'],
    [102, 'mp9'],
    [103, 'bizon'],
    [104, 'mac10'],
    [105, 'ump45'],
    [106, 'p90'],
    [107, 'mp5sd'],

    [201, 'sawedoff'],
    [202, 'nova'],
    [203, 'mag7'],
    [204, 'xm1014'],
    [205, 'm249'],
    [206, 'negev'],

    [301, 'galilar'],
    [302, 'famas'],
    [303, 'ak47'],
    [304, 'm4a1'],
    [305, 'm4a1_silencer'],
    [306, 'ssg08'],
    [307, 'sg556'],
    [308, 'aug'],
    [309, 'awp'],
    [310, 'scar20'],
    [311, 'g3sg1'],
  ])
  if (wepaonMap.get(weaponId)){
    return `/images/weapons/${wepaonMap.get(weaponId)}.svg`
  } else {
    weaponId
  }
}

export const LoadImage = (mapName) => {
    let img = new Image()
    let background = document.querySelector('.bg-img')

    img.onload = function() {
      if (background) {
        background.src = img.src
      }
    }

    img.onerror = function () {
      img.src = `/images/map_screenshots/${mapName}.png`
      img.onerror = null
    }

    img.src = `/images/map_screenshots/${mapName}.webp`
}
