<template>
  <img alt="" class="bg-img" src="">
  <header>
    <CompNav/>
  </header>
  <main>
    <div :style="{height: offset + 'px'}"/>
    <InfoModal/>
    <router-view name="main"/>
  </main>
  <footer class="mt-auto">
    <CompFooter/>
  </footer>
  <CookieConsentBtn id="cookie-btn"/>
</template>

<script>
import {onMounted, ref} from "vue";
import InfoModal from "@/components/InfoModal";
import CompFooter from "@/components/CompFooter";
import CompNav from "@/components/CompNav";

export default {
  components: {CompNav, CompFooter, InfoModal},
  setup() {
    const offset = ref(0)

    const setOffset = () => {
      return document.getElementsByTagName('nav')[0].clientHeight
    }

    const setBgHeight = () => {
      document.querySelector('.bg-img').style.height = document.documentElement.clientHeight + 'px'
    }

    window.onresize = () => {
      offset.value = setOffset()
      setBgHeight()
    }

    onMounted(() => {
      offset.value = setOffset()
      setBgHeight()
    })

    return {offset}
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: "Obitron";
}

.bg-img {
  z-index: -1;
  position: fixed;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
}

#cookie-btn {
  position: fixed;
  bottom: 30px;
  right: 20px;
}
</style>
