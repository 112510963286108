import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
import '@/scss/custom.scss'
import VueMatomo from 'vue-matomo'

const app = createApp(App)

app.use(store)
app.use(router)

if (process.env.VUE_APP_TRACKING) {
  app.use(
    VueMatomo, {
      host: process.env.VUE_APP_TRACK_URL,
      siteId: process.env.VUE_APP_TRACK_ID,
      router: router,
      disableCookies: true,
    }
  )
}

app.mount('#app')
